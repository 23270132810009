<template>
  <div class="indexPage">
    <div class="content_area">
      <div class="mainVideo">
        <AliyunVideo
          :AliyunId="mainVideoId"
          class="mainVideoContent"
          :autoplay="false"
        ></AliyunVideo>
      </div>
    </div>
    <setTalkDialog ref="setTalk"></setTalkDialog>
  </div>
</template>

<script>
import setTalkDialog from "~nbk/components/talk/setTalk";
import AliyunVideo from "~/baseComponents/AliyunVideo";
import { SSstore } from "~/baseUtils/storage";
export default {
  components: {
    setTalkDialog,
    AliyunVideo,
  },
  data() {
    return {
      mainVideoTitle: this.$t("quicklyKnowExpo"),
      mainVideoId: "5941cb593ac3468cb5030d5517fa82af",
      videoList: [
        // {
        //   aliyun_id: "f2910721892d4bcea23058547dab36f4",
        //   cover_img:
        //     "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/helpCenter/1v1.png",
        //   video_name: "如何进行1v1洽谈",
        // },
      ],
    };
  },
  mounted() {
    document.title = "2022 China (Ningbo) Export Cross-border E-commerce Expo";
    this.handleUserMeetingStatus({
      notLogin: () => {
        this.$router.push("/sign");
      },
      notCompany: () => {
        this.$router.push("/register");
      },
      notEnroll: () => {
        this.$router.push("/register");
      },
      enrollComplete: () => {
        //this.$router.push("/");
        if (SSstore.get("formLogin")) {
          this.getList();
          SSstore.remove("formLogin");
        }
      },
    });
  },
  methods: {
    async getList() {
      let params = {
        meeting_id: this.MEETING_ID,
        user_id: this.USER_INFO.id,
        nopage: 1,
        is_self: true,
      };
      let res = await this.$store.dispatch(
        "baseConsole/getnegotationsetList",
        params
      );
      if (res.success) {
        let howHour = (res.total_time / 3600).toFixed(1);
        this.$store.commit("SET_TALK_DURATION", howHour);
        if (this.TALK_DURATON < 10) {
          this.$refs.setTalk.hintVisibleShow(true);
        } else {
          this.$refs.setTalk.hintVisibleShow(false);
        }
      }
    },
    playVideo(id) {
      this.$refs.playVideo.startPlay(id);
    },
  },
};
</script>

<style scoped lang="less">
.indexPage {
  padding: 30px 0px;
  background: #f6f7f9;
  .pageTitle {
    font-size: 24px;
    font-weight: 800;
    color: #333333;
    line-height: 33px;
    margin-bottom: 16px;
  }
  .mainVideo {
    width: 1300px;
    height: 614px;
    border: 1px solid #e9e9e9;
  }
}
</style>